import { useEffect, useState } from 'react';
import { IWAGLogo } from '@/components/icons/iwag-logo';
import { defaultLocale, useTranslatedPath as getTranslatedPath, type Locales } from '@/i18n';
import { getField } from '@/utils/persistence';

type Props = { title: string };

export default function ClientRedirect({ title }: Props) {
  const [lang, setLang] = useState<Locales>(defaultLocale);
  const [redirectPath, setRedirectPath] = useState<string | null>(null);

  useEffect(() => {
    const persistedLang = getField<Locales>('lang', defaultLocale);
    setLang(persistedLang);
  }, []);

  useEffect(() => {
    if (!lang) {
      return;
    }

    const translatePath = getTranslatedPath(lang);
    const { pathname } = window.location;

    const newRedirectPath = translatePath(pathname, lang);
    setRedirectPath(newRedirectPath);
  }, [lang]);

  useEffect(() => {
    if (redirectPath) {
      const refreshMeta = document.createElement('meta');
      refreshMeta.setAttribute('http-equiv', 'refresh');
      refreshMeta.setAttribute('content', `2;url=${redirectPath}`);

      const oldRefreshMeta = document.head.querySelector('meta[http-equiv="refresh"]');

      if (oldRefreshMeta) {
        oldRefreshMeta.replaceWith(refreshMeta);
      } else {
        document.head.appendChild(refreshMeta);
      }
    }
  }, [redirectPath]);

  return (
    <main className="flex min-h-svh w-full flex-col items-center justify-center overflow-x-hidden">
      <div className="flex flex-col items-center gap-4 text-center">
        <IWAGLogo className="h-16 text-white lg:h-24" />
        <div className="flex flex-col gap-1">
          <p className="text-base leading-tight lg:text-lg">Redirecting you to {title}...</p>
          <a className="text-base leading-tight text-primary-400 underline lg:text-lg" href={redirectPath ?? ''}>
            Click here if you aren't redirected
          </a>
        </div>
      </div>
    </main>
  );
}
