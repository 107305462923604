import { type SVGProps } from 'react';

export function IWAGLogo({ role = 'img', height, width, fill = 'currentColor', ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      role={role}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 285.087 180.147"
      {...rest}
    >
      <path
        d="M34.795 28.926S-1.108 51.034.026 90.526s42.138 62.167 42.138 62.167-17.196-41.57-10.96-78.228C37.44 37.808 71.641 10.03 71.641 10.03s-13.604 4.913-26.075 13.416C33.91 31.393 29.693 40.075 29.693 40.075s.778-1.938 2.268-5.29a95.953 95.953 0 012.834-5.859"
        fill={fill}
      />
      <path
        d="M51.423 65.018l21.351-2.646S64.875 78.28 62.041 92.64s-3.439 28.12-3.439 28.12 4.229-12.288 9.351-21.75C74.047 87.754 81.09 80.701 81.09 80.701l15.117 1.513s.685 12.566 2.574 22.77 5.172 18.423 5.172 18.423 1.146-17.31 6.742-34.2c5.49-16.566 11.02-24.567 11.02-24.567l21.282-2.398s-12.022 24.009-17.314 60.666c-5.29 36.658-4.157 56.24-4.157 56.24l-17.573-3.402s-9.246-20.427-13.78-37.245-7.194-34.37-7.194-34.37-8.974 13.486-13.132 29.547c-4.156 16.061-5.763 30.732-5.763 30.732l-14.655-7.133s-6.546-26.488-6.143-51.894c.298-18.784 8.138-40.364 8.138-40.364"
        fill={fill}
      />
      <path
        d="M93.794 62.229c10.375.768 29.346-13.062 29.346-20.59 0-7.247-16.132-8.143-23.815-8.604s-18.198.3-21.05 5.685c-2.765 5.225 5.144 22.74 15.52 23.508m65.452-27.197s-14.135-23.046-13.828-26.426 4.917-8.45 6.913-8.604 13.345 10.84 13.345 10.84 1.252-.7 3.404-.7 3.448.638 3.448.638 1.614-.518 4.227-2.977 4.155-5.957 4.155-5.957 1.66 3.623 1.383 8.297c-.29 4.874-1.507 5.886-1.507 5.886l2.89 2.718s3.077-.781 5.602-2.37c2.547-1.602 5.767-4.697 5.767-4.697s.154 8.296-2.458 13.674-5.685 7.53-5.685 7.53-5.437 33.136 3.564 67.552c8.996 34.399 26.298 68.769 26.298 68.769s-10.06 2.823-16.238 4.765c-6.176 1.941-13.236 2.647-13.236 2.647s-6.001-19.59-9.353-35.121c-3.355-15.533-5.296-35.829-5.296-35.829l-8.824.706s.882-6.178 1.412-11.12.882-7.235.882-7.235l4.942-.353s-.706-4.765-.883-11.12-.177-15.002-.177-15.002-8.647 23.174-11.294 62.886c-2.631 39.466.705 55.365.705 55.365s-7.765.353-13.237.353-10.413-.353-10.413-.353 1.51-46.85 6.805-78.441 16.688-66.32 16.688-66.32"
        fill={fill}
      />
      <path
        d="M273.945 94.288s-6.026-13.776-18.655-14.494c-12.628-.717-23.821 8.898-23.821 8.898s13.633-1.723 22.53 5.165c8.897 6.888 8.18 18.799 3.014 25.973s-16.933 10.475-26.404 4.592-17.077-28.987-14.064-46.35 11.768-22.959 20.664-22.386c8.897.575 11.193 6.314 14.638 5.453s6.313-2.152 6.744-4.017c.43-1.866-7.032-10.62-7.032-10.62s-.144-1.865-.287-3.586c-.144-1.723-1.004-3.445-1.004-3.445s.717-3.014 3.3-5.31 5.309-4.448 5.309-4.448-6.743-.718-10.619.43c-3.704 1.098-5.309 2.727-5.309 2.727l-3.3-1.292s.86-2.726 3.443-4.88c2.583-2.151 4.449-3.586 4.449-3.586s-5.309-1.723-10.906 1.147c-5.596 2.872-7.318 5.885-7.318 5.885s-17.795 7.605-26.404 28.843c-8.61 21.238-5.023 44.197.86 59.409 5.885 15.21 17.651 29.704 38.172 31.283 20.52 1.577 34.727-13.202 39.319-23.821s5.31-26.548.717-34.44c-4.592-7.893-10.332-11.624-10.332-11.624s2.296 2.87 2.583 6.6c.287 3.732-.287 7.894-.287 7.894"
        fill={fill}
      />
    </svg>
  );
}
